import { Form, Input, Space, Tag } from "antd"
import React, { useRef, useState } from "react"

import PhoneInput from "react-phone-number-input/input"
import "react-phone-number-input/style.css"
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input"
import textAndRules from "../../../../utils/textAndRules"
const FormPhoneNumber = ({ label, inputPhone }) => {
  const checkInputPhoneNumber = (_, value) => {
    if (value) {
      if (isValidPhoneNumber(value)) {
        const array = formatPhoneNumber(value).split("")
        if (array[1] == "0") {
          return Promise.reject(
            new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
          )
        }
        if (array[0] !== inputPhone?.split("")[0]) {
          return Promise.reject(
            new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
          )
        }
        if (array[1] !== inputPhone?.split("")[1]) {
          return Promise.reject(
            new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
          )
        }
        return Promise.resolve()
      }

      return Promise.reject(
        new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
      )
    }

    return Promise.reject(new Error(""))
  }
  return (
    <Form.Item
      label={
        <div className="flex">
          <Tag style={{ display: "inline" }} color="red">
            必須
          </Tag>
          <div className="font-medium font-notoSans text-[#333]">{label}</div>
        </div>
      }
    >
      <Form.Item
        name={"phone_company"}
        rules={[
          {
            required: true,
            message: textAndRules.pleaseInput,
          },
          {
            validator: checkInputPhoneNumber,
          },
        ]}
      >
        <PhoneInput
          country="JP"
          placeholder={"000-0000-0000"}
          className="inputNumberPhone"
        />
      </Form.Item>
    </Form.Item>
  )
}

export default FormPhoneNumber
