import { Form, Input, Space, Tag } from "antd"
import React from "react"
import textAndRules from "../../../../utils/textAndRules"

const FormInputTwo = ({
  label,
  nameFist,
  name,
  placeholder,
  placeholderFirst,
  kana,
}) => {
  return (
    <Form.Item
      label={
        <div className="flex">
          <Tag style={{ display: "inline" }} color="red">
            必須
          </Tag>
          <div className="font-medium font-notoSans text-[#333]">{label}</div>
        </div>
      }
    >
      <Space>
        <Form.Item
          name={nameFist}
          rules={[
            {
              required: true,
              message: textAndRules.pleaseInput,
            },
            {
              whitespace: true,
              message: "空白を入力できません。",
            },
            kana
              ? {
                  pattern: /^[\u{30a0}-\u{30ff}]+$/mu,
                  message: "カタカナを入力してください。",
                }
              : {},
          ]}
        >
          <Input placeholder={placeholderFirst} className="!max-w-[300px]" />
        </Form.Item>
        <Form.Item
          name={name}
          rules={[
            {
              required: true,
              message: textAndRules.pleaseInput,
            },
            {
              whitespace: true,
              message: "空白を入力できません。",
            },
            kana === true
              ? {
                  pattern: /^[\u{30a0}-\u{30ff}]+$/mu,
                  message: "カタカナを入力してください。",
                }
              : {},
          ]}
        >
          <Input placeholder={placeholder} className="!max-w-[300px]" />
        </Form.Item>
      </Space>
    </Form.Item>
  )
}

export default React.memo(FormInputTwo)
