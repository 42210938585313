import { Form, Button, Spin } from "antd"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { dataForm } from "."
import {
  openNotificationFail,
  openNotificationSuccess,
} from "../../../../components/notification"
import { updateStore, updateStoreById } from "../../../../services/user"
import FormInput from "./FormInput"
import "./style.scss"
import * as wanakana from "wanakana"
import { MinusCircleOutlined } from "@ant-design/icons"
import { changeTextToKatakana } from "../../../../services/superAdManageCompany"

const addressName = ["region", "city", "town", "building_name"]

const InfoCompanyBasic = ({ stores, loading, setLoading }) => {
  const [arrayWholesale, setArrayWholesale] = useState([Date.now()])
  const [arrayWholesaleOrigin, setArrayWholesaleOrigin] = useState([Date.now()])
  const [objectWholesaleOrigin, setObjectWholesaleOrigin] = useState({})

  const typingTimeoutRef1 = useRef(null)
  const typingTimeoutRef2 = useRef(null)
  const typingTimeoutRef3 = useRef(null)

  const [formref1] = Form.useForm()
  const [formref2] = Form.useForm()
  const [formref3] = Form.useForm()
  const [formref4] = Form.useForm()
  const [formref5] = Form.useForm()
  const [formref6] = Form.useForm()
  const [formref7] = Form.useForm()
  const [formref8] = Form.useForm()
  const [formref9] = Form.useForm()

  useEffect(() => {
    if (stores?.list_wholesale) {
      setArrayWholesale(stores.list_wholesale)
      setArrayWholesaleOrigin(stores.list_wholesale)
      setObjectWholesaleOrigin(stores.wholesale_companies)
    }
  }, [stores])

  const checkKatana = (text) => {
    if (wanakana.isKatakana(text)) {
      return true
    } else {
      return false
    }
  }

  const changeText1 = (value) => {
    if (typingTimeoutRef1.current) {
      clearTimeout(typingTimeoutRef1.current)
    }
    typingTimeoutRef1.current = setTimeout(async () => {
      const { response } = await changeTextToKatakana({
        content: value,
        type: "katakana",
      })
      if (response.status == 200) {
        formref2?.setFieldsValue({
          name_kana: response?.data?.result,
        })
      }
    }, 500)
  }
  const changeText2 = (value) => {
    if (typingTimeoutRef2.current) {
      clearTimeout(typingTimeoutRef2.current)
    }
    typingTimeoutRef2.current = setTimeout(async () => {
      const { response } = await changeTextToKatakana({
        content: value,
        type: "katakana",
      })
      if (response.status == 200) {
        formref5?.setFieldsValue({
          rep_name_kana_first: response?.data?.result,
        })
      }
    }, 500)
  }
  const changeText3 = (value) => {
    if (typingTimeoutRef3.current) {
      clearTimeout(typingTimeoutRef3.current)
    }
    typingTimeoutRef3.current = setTimeout(async () => {
      const { response } = await changeTextToKatakana({
        content: value,
        type: "katakana",
      })
      if (response.status == 200) {
        formref5?.setFieldsValue({
          rep_name_kana: response?.data?.result,
        })
      }
    }, 500)
  }

  const convertText = (text, form) => {
    switch (form) {
      case "form1":
        changeText1(text)
        break
      case "form4_rep_name":
        changeText2(text)
        break
      case "form4_rep_name_first":
        changeText3(text)
    }
  }

  const handleRemoveWholeSale = async (item) => {
    console.log("item", item)
    // setInfo((prev) => {
    //   const newObj = { ...prev.wholesale_companies }
    //   delete newObj[myIndex]
    //   callApi.store.update({ wholesale_companies: newObj })
    //   return { ...prev, wholesale_companies: { ...newObj } }
    // })
    // const { data } = await updateStore(
    //   stores.id,
    //   {
    //     forEverything: false,
    //   },
    //   values
    // )
    // setLoading(false)
    // if (data) {
    //   openNotificationSuccess("保存しました。", "", "green")
    // } else {
    //   openNotificationFail("正しい情報を入力してください", "", "red")
    // }
    if (!arrayWholesaleOrigin?.some((ele) => ele == item)) {
      arrayWholesale.splice(arrayWholesale.indexOf(item), 1)
      setArrayWholesale([...arrayWholesale])
    } else {
      const wholesale_companies = {
        ...objectWholesaleOrigin,
      }
      delete wholesale_companies[item?.toString()]
      console.log("wholesale_companies", wholesale_companies)
      const values = {}
      values.wholesale_companies = wholesale_companies
      setLoading(true)

      const { data } = await updateStore(
        stores.id,
        {
          forEverything: false,
        },
        values
      )
      setLoading(false)
      if (data) {
        openNotificationSuccess("保存しました。", "", "green")
        arrayWholesale.splice(arrayWholesale.indexOf(item), 1)
        setArrayWholesale([...arrayWholesale])
        arrayWholesaleOrigin.splice(arrayWholesaleOrigin.indexOf(item), 1)
        setArrayWholesaleOrigin([...arrayWholesaleOrigin])
        setObjectWholesaleOrigin(wholesale_companies)
      } else {
        openNotificationFail("正しい情報を入力してください", "", "red")
      }
    }
  }
  return (
    <Spin spinning={loading}>
      <div className="p-[50px] bg-white infoCompanyBasic">
        <div className="max-w-screen-large mx-auto">
          <Form.Provider
            onFormFinish={async (name, { values }) => {
              console.log("name", name)
              console.log("values", values)
              if (name === "form3") {
                values.address.desc.split(", ")?.forEach((item, index) => {
                  values.address[addressName[index]] = item
                })
                delete values.address.desc
              }

              if (name === "form7") {
                values.founded_at =
                  moment(values.founded_at.year).format("YYYY") +
                  "-" +
                  moment(values.founded_at.month).format("MM")
              }
              if (name.includes("wholesale_companies")) {
                const wholesale_companies = {
                  ...objectWholesaleOrigin,
                  ...values.wholesale_companies,
                }
                values.wholesale_companies = wholesale_companies
              }
              setLoading(true)
              const { data } = await updateStore(
                stores.id,
                {
                  forEverything: false,
                },
                values
              )
              setLoading(false)
              if (data) {
                if (name.includes("wholesale_companies")) {
                  const array = Object.entries(values.wholesale_companies)?.map(
                    (item) => item[0]
                  )
                  setArrayWholesaleOrigin(array)
                  setObjectWholesaleOrigin(values.wholesale_companies)
                }
                openNotificationSuccess("保存しました。", "", "green")
              } else {
                openNotificationFail("正しい情報を入力してください", "", "red")
              }
            }}
          >
            {dataForm.map((form, index) => (
              <FormInput
                key={form.name}
                {...form}
                form={
                  index == 0
                    ? formref1
                    : index == 1
                    ? formref2
                    : index == 2
                    ? formref3
                    : index == 3
                    ? formref4
                    : index == 4
                    ? formref5
                    : index == 5
                    ? formref6
                    : index == 6
                    ? formref7
                    : index == 7
                    ? formref8
                    : index == 8
                    ? formref9
                    : null
                }
                convertText={convertText}
                stores={stores}
                setLoading={setLoading}
              />
            ))}
            {arrayWholesale.map((item, index) => (
              <div key={item} className="flex gap-[10px]   w-full">
                <FormInput
                  stores={stores}
                  name={`wholesale_companies${index}`}
                  required={false}
                  nameItem={["wholesale_companies", `${item}`]}
                  label="指定卸会社"
                  placeholder="指定卸会社を記入してください。"
                />
                {index > 0 && (
                  <div
                    className=" cursor-pointer flex items-center pb-[10px]"
                    onClick={() => handleRemoveWholeSale(item)}
                  >
                    <MinusCircleOutlined />
                  </div>
                )}
              </div>
            ))}
            <Button
              className="mt-8 !w-[200px]"
              type="danger"
              onClick={() => setArrayWholesale((prev) => [...prev, Date.now()])}
            >
              ＋ 指定卸会社を追加
            </Button>
          </Form.Provider>
        </div>
      </div>
    </Spin>
  )
}

export default InfoCompanyBasic
