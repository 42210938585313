import { Form, Tag, Input, Select, InputNumber } from "antd"
import React, { useMemo } from "react"
import textAndRules from "../../../../utils/textAndRules"

const FormInputOne = ({
  nameItem,
  required,
  label,
  placeholder,
  type,
  kana,
  parser,
  formatter,
  maxLength,
}) => {
  const rules = useMemo(() => {
    const arr = [
      {
        required: required === false ? false : true,
        message: textAndRules.pleaseInput,
      },
    ]
    if (type !== "number") {
      arr.push({
        whitespace: true,
        message: "空白を入力できません。",
      })
    }
    if (kana) {
      arr.push({
        pattern: /^[\u{30a0}-\u{30ff}]+$/mu,
        message: "カタカナを入力してください。",
      })
    }
    if (type === "url") {
      arr.push({
        type: "url",
        message: "ホームページ のリンクを入力してください。",
      })
    }
    return arr
  }, [kana, type])

  return (
    <Form.Item
      name={nameItem}
      label={
        <div className="flex">
          <Tag
            style={{ display: "inline" }}
            color={required === false ? "default" : "red"}
          >
            {required === false ? "任意" : "必須"}
          </Tag>
          <div className="font-medium font-notoSans text-[#333]">{label}</div>
        </div>
      }
      rules={rules}
    >
      {type === "select" ? (
        <Select
          placeholder={placeholder}
          options={[
            {
              value: "admin",
              label: "普通",
            },
            {
              value: "staff_order",
              label: "当座",
            },
          ]}
        />
      ) : type === "number" ? (
        <InputNumber
          style={{
            width: "100%",
          }}
          maxLength={maxLength}
          placeholder={placeholder}
          formatter={formatter ? formatter : undefined}
          parser={parser ? parser : undefined}
        />
      ) : (
        <Input placeholder={placeholder} />
      )}
    </Form.Item>
  )
}

export default React.memo(FormInputOne)
