import InfoCompanyBasic from "./InfoCompanyBasic"

export const dataForm = [
  {
    name: "form1",
    nameItem: "name",
    label: "会社名 ※㈱は不可",
    placeholder: "正式名称で記入してください。",
  },
  {
    name: "form2",
    kana: true,
    nameItem: "name_kana",
    label: "会社名 カナ",
    placeholder: "ミスターフード株式会社",
  },
  {
    type: "address",
    name: "form3",
    nameItem: "address",
    label: "住所",
    placeholder: "ミスターフード株式会社",
  },
  {
    type: "second",
    name: "form4",
    nameItem: "rep_name_first",
    nameItemFirst: "rep_name",
    label: "代表者名",
    placeholder: "浩二",
    placeholderFirst: "山田",
  },
  {
    type: "second",
    name: "form5",
    kana: true,
    nameItem: "rep_name_kana",
    nameItemFirst: "rep_name_kana_first",
    label: "代表者名 カナ",
    placeholder: "ヤマダ",
    placeholderFirst: "コウジ",
  },
  {
    type: "phone",
    name: "form6",
    label: "電話番号",
  },
  {
    type: "date",
    name: "form7",
    label: "法人設立年月",
  },
  {
    name: "form8",
    type: "url",
    nameItem: "company_home_url",
    label: "ホームページURL",
    placeholder: "www.nametaro.com",
  },
]

export default InfoCompanyBasic
