import { Button, Form, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { dataForm } from "."
import {
  openNotificationFail,
  openNotificationSuccess,
} from "../../../../components/notification"
import { updateStore } from "../../../../services/user"
import FormInput from "../InfoCompanyBasic/FormInput"

const InfoBankCompany = ({ stores, loading, setLoading }) => {
  const [isCheck, setIsCheck] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (isCheck && data) {
      ;(async () => {
        setLoading(true)
        const res = await updateStore(
          stores.id,
          {
            forEverything: true,
            page: 2,
          },
          data
        )
        setLoading(false)
        if (res.data) {
          openNotificationSuccess("保存しました。", "", "green")
        } else {
          openNotificationFail("正しい情報を入力してください", "", "red")
        }
      })()
    }
  }, [isCheck, data])

  return (
    <Spin spinning={loading}>
      <div className="p-[50px] bg-white infoCompanyBasic">
        <div className="max-w-screen-large mx-auto">
          <Form.Provider
            onFormFinish={async (name, { values, forms }) => {
              if (name === "submit") {
                delete forms.submit
                let obj = {}
                let isValid = true
                Object.entries(forms).forEach(([_, value]) => {
                  if (
                    Object.values(value.getFieldsValue()).every(
                      (x) => x === null || x === ""
                    )
                  ) {
                    isValid = false
                    value.submit()
                  }
                  obj = {
                    ...obj,
                    ...value.getFieldsValue(),
                  }
                })
                setIsCheck(isValid)
                setData(obj)
              } else {
                setLoading(true)
                const { data } = await updateStore(
                  stores.id,
                  {
                    forEverything: false,
                  },
                  values
                )
                setLoading(false)
                if (data) {
                  openNotificationSuccess("保存しました。", "", "green")
                } else {
                  openNotificationFail(
                    "正しい情報を入力してください",
                    "",
                    "red"
                  )
                }
              }
            }}
          >
            {dataForm.map((form) => (
              <FormInput
                key={form.name}
                {...form}
                stores={stores}
                setLoading={setLoading}
              />
            ))}
            <div className="flex justify-center pt-[60px] mt-[43px] border-t border-[rgba(0,0,0,0.06)]">
              <Form name="submit">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="!w-[329px] !h-[37px]"
                >
                  更新する
                </Button>
              </Form>
            </div>
          </Form.Provider>
        </div>
      </div>
    </Spin>
  )
}

export default InfoBankCompany
